@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Teko:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Hind", "Poppins", sans-serif;
  line-height: 1.5;
}

:root {
  --orange: #f9520b;
  --darkblues: #17a2b8;
  --darkblue1: #130d65;
  --primarys: #00ced1;
  --darkblue: #00042a;
}

.client {
  height: 100vh;
}

::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: var(
    --darkblue
  ); /* Set the color of the thumb (the draggable part) */
  border-radius: 4px; /* Set the border radius to make it rounded */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the color of the track (the non-draggable part) */
}

/* Optional: Handle when the scrollbar is hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--darkblue); /* Change color on hover */
}

.readmore {
  background: linear-gradient(to right, #ff5066, #fc9d44);
  padding: 5px 10px;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
}

/* background: conic-gradient(
    from 180deg at 50% 50%,
    #04e4ff 0deg,
    #04e4ff 54.38deg,
    #009cff 93deg,
    #8842dc 140deg,
    #8842dc 257deg,
    #009cff 275deg,
    #04e4ff 285deg,
    #04e4ff 360deg
    #00ffd2
  ); */

.text-glow-blue {
  background: linear-gradient(131.8deg, #04e4ff 0, #009cff 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #04e4ff;
  color: transparent;
  text-shadow: 0 0 10px rgba(4, 228, 255, 0.5);
}

.whatapp {
  background: #25d366;
  color: white;
  font-size: 2rem;
  border-radius: 50%;
  bottom: 3%;
  right: 4%;
  position: fixed;
  cursor: pointer;
  animation: boxshadow 1s infinite alternate;
}

@keyframes boxshadow {
  0% {
    box-shadow: 0px 0px 5px white;
  }
  100% {
    box-shadow: 0px 0px 20px white;
  }
}

.main-header {
  text-align: center;
  padding: 10px;
  color: white;
  background: url("./asset/mainheader.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: 0% 53%;
}

.main-header > a {
  color: #ff5066;
  text-decoration: none;
  font-weight: bold;
}

/*home css*/

.process-grid {
  text-align: center;
  font-size: 0.9rem;
}

.choose-us {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
}

.choose-us > svg {
  margin-right: 10px;
  color: #ff5066;
  font-size: 1.1rem;
  min-width: 30px;
  margin-top: 5px;
}

.choose-container {
  background: #e0f3f1;
  padding: 100px;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .choose-container {
    padding: 30px;
  }
  .process {
    background: linear-gradient(90deg, #41ffb9, #0040ff);
    padding: 15px 30px;
    text-align: center;
    position: static;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 601px) {
  .process {
    /* background: orange; */
    background: linear-gradient(90deg, #41ffb9, #0040ff);
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%) !important;
    left: 50%;
    padding: 15px 30px;
    text-align: center;
    width: 450px;
  }
}
