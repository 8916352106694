.home {
  padding: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: url(../../asset/bg1.jpg);
  background-attachment: fixed;
  background-position: top;
}

.main-content {
  max-width: 600px;
  font-size: 0.9rem;
  margin-top: 5px;
}

h1.welcome {
  font-size: 2.5rem;
}

h5.sub-text {
  margin: 10px;
  font-weight: bold;
  font-size: 1.1rem;
  color: #00aaff;
}

.main-content {
  font-size: 1rem;
  margin-bottom: 10px;
  color: white;
}

@media only screen and (min-width: 1001px) {
  .home {
    height: 80vh;
  }
}

@media only screen and (max-width: 1000px) {
  h1.welcome {
    font-size: 1.5rem;
  }
  .main-content {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 600px) {
  .home {
    padding: 80px 50px;
  }
  h1.welcome {
    font-size: 1.5rem;
  }
  .main-content {
    font-size: 0.9rem;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
