.portfolio {
  padding: 100px;
  background: linear-gradient(45deg, darkblue, darkorchid);
}

.folio-sec {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 100px;
}

.heading {
  text-align: center;
  position: relative;
  color: blue;
  padding: 5px;
  margin-bottom: 30px;
}

.heading::before {
  content: " ";
  position: absolute;
  height: 3px;
  width: 30px;
  background: var(--orange);
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.folios:hover {
  background: white;
  border-radius: 3px;
  cursor: pointer;
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .portfolio {
    padding: 50px 30px;
  }
}
