main.foundation {
  text-align: center;
  padding: 100px;
}

.members {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fnd-img {
  background: blue;
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

main.mainservice {
  padding: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}

.service-card {
  max-width: 300px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: all 1s;
}

.service-card:hover {
  box-shadow: 0 0px 30px 5px rgb(0 0 0 / 25%);
  transform: translateY(-10px);
}

.service-card > svg {
  font-size: 2rem;
  color: springgreen;
}

@media only screen and (max-width: 1280px) {
  main.mainservice {
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  main.mainservice {
    justify-content: center;
    padding: 30px;
  }
}
