.header,
.sub-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 80px;
}
.header {
  /* position: fixed; */
  position: sticky;
  top: 0;
  width: 100%;
  min-height: 60px;
  transition: all 1s ease-in-out;
  z-index: 3;
  height: 60px;
}
.darkblue {
  color: white;
  background-image: url("../../asset/104053.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  animation: bganme 10s infinite;
}

@keyframes bganme {
  0% {
    background-position: bottom;
  }
  50% {
    background-position: top;
  }
  100% {
    background-position: bottom;
  }
}

.headlinks {
  padding: 2px 10px;
  cursor: pointer;
  font-weight: bold;
  color: white;
}

.orange {
  background: var(--darkblue);
}

.sub-header {
  font-size: 0.9rem;
  text-transform: capitalize;
  color: #01afe9;
}

.sub-items {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.sub-items > svg {
  font-size: 1.3rem;
  margin-right: 5px;
}

.active {
  background: white;
  color: black;
}

img.logo {
  width: 60px;
  object-fit: contain;
  cursor: pointer;
}

.signin {
  padding: 3px 15px;
  background: white;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 0.98rem;
  border-radius: 5px;
  background-image: linear-gradient(to right, darkblue, darkorchid);
  color: white;
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 5px white;
}

.nav-container {
  display: flex;
  align-items: center;
}

.mobile-icon {
  color: white;
  cursor: pointer;
  font-size: 20px;
}

.mob-sec {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.mob-sec:hover {
  background: green;
  border-radius: 50%;
}

@media only screen and (max-width: 600px) {
  .header {
    padding: 10px 30px;
  }
}

@media only screen and (max-width: 1044px) {
  .nav-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 61px;
    width: 100%;
    left: 0;
    align-items: flex-start;
    padding: 15px 30px;
    background: linear-gradient(45deg, darkblue, darkorchid);
  }
  .sub-header {
    display: none;
  }
  nav {
    display: flex;
    flex-direction: column;
  }
  .nav-container.mob-nav-close {
    scale: 0;
  }
}
@media only screen and (min-width: 1045px) {
  .mobile-icon {
    display: none;
  }
}

.log-container {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

.login-form {
  background: white;
  padding: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form > input {
  padding: 10px;
  width: 250px;
  margin-bottom: 5px;
  border: none;
  background: #dbdbdb;
  border-radius: 3px;
  outline: none;
}
