.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px;
  gap: 20px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../asset/servicebg.jpg") no-repeat center center/cover; /* Example background image */
  backdrop-filter: blur(5px);
  border-radius: 20px;
  margin: 20px;
}

.service-card {
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  max-width: 300px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}

.service-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.service-title {
  font-size: 1.5rem;
  color: #333;
  margin: 10px 0;
}

.service-description {
  font-size: 1rem;
  color: #333;
}

@media (max-width: 768px) {
  .service-card {
    max-width: 100%;
  }
}
