.footer {
  background: linear-gradient(89deg, #0570b8 0%, #009cff 100%);
  color: white;
  padding: 30px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyright {
  font-size: 1rem;
  position: relative;
  padding-right: 20px;
}

.sec1::after {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  background: linear-gradient(94deg, #a4dcff -2.26%, #30afff 63.14%);
  top: 0;
  right: 0;
}

.address {
  word-wrap: break-word;
  line-height: 1.4;
  font-weight: bold;
  font-size: 0.9rem;
}

svg.f-icon {
  font-size: 24px;
  padding: 3px;
  margin-right: 5px;
}

.social-links {
  margin-top: 5px;
}

.social-links > a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  padding: 2px;
  cursor: pointer;
  margin-right: 5px;
}

@media only screen and (max-width: 600px) {
  .footer {
    padding: 20px 40px;
    flex-direction: column;
  }
  .copyright {
    font-size: 0.8rem;
    margin-bottom: 15px;
  }
  .sec1 {
    text-align: center;
  }

  /* .sec1 > svg {
    font-size: 24px;
    padding: 2px;
    cursor: pointer;
  } */

  .address {
    font-size: 0.9rem;
    text-align: center;
  }
}
